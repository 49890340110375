div.single-question-details {
    margin-bottom: 40px;
}

h2.single-question-title {
    font-family: Roboto-Light;
    font-size: 18pt;
    margin-bottom: 5px;
}

div.question-detail-pills {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

div.single-option {
    margin-bottom: 8px;
}

div.single-option span {
    font-family: Roboto-Light;
    font-size: 14pt;
    margin-left: 5px;
}

span.checkmark-placeholder {
    display: inline-block;
    width: 11px;
}