#admin-page {
    min-height: 100vh;
    padding-bottom: 40px;
}

#forbidden-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#forbidden-content {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
}

#forbidden-main {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
}

#forbidden-main img {
    max-height: 300px;
}

#forbidden-main h1 {
    margin-left: 50px;
    font-size: 72pt;
}

#forbidden-page p {
    font-size: 16pt;
}