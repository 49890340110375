* {
  margin: 0;
  padding: 0;
}

body {
  font-family: NotoSans;
  color: #333;
}

/* Fonts */
@font-face {
  font-family: 'Flexo-Demi';
  src: local('Flexo-Demi'), url(./fonts/flexo/Flexo-Demi.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Flexo-Heavy';
  src: local('Flexo-Heavy'), url(./fonts/flexo/Flexo-Heavy.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Flexo';
  src: local('Flexo'), url(./fonts/flexo/Flexo-Medium.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Flexo-Medium';
  src: local('Flexo'), url(./fonts/flexo/Flexo-Medium.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Flexo-Light';
  src: local('Flexo-Light'), url(./fonts/flexo/Flexo-Light.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Flexo-Thin';
  src: local('Flexo-Thin'), url(./fonts/flexo/Flexo-Thin.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans'), url(./fonts/notoSans/NotoSans-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: local('NotoSans-Bold'), url(./fonts/notoSans/NotoSans-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto-Thin'), url(./fonts/Roboto/Roboto-Thin.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'), url(./fonts/Roboto/Roboto-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


.flex-center {
  display: flex;
  justify-content: center;
}

.button-main:hover {
  background-color: #039651;
}

div.spinner-container {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


div#invalid-url {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: flexo-light;
}

@media (max-width: 500px) {
  div#invalid-url {
    height: 50vh;
  }
  div#invalid-url h1 {
    font-size: 14pt;
  }
}