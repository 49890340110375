.pill-base-container {
    font-family: NotoSans-Bold;
    font-weight: bold;
    font-size: 11pt;
    white-space: nowrap;
    display: inline-block;
    box-sizing: border-box;
    user-select: none;
}

.pill-base-container * {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: .3em .6em;
    display: inline-block;
    box-sizing: border-box;
}

.pill-left-text {
    border-radius: 4px 0 0 4px;
    /* From https://css-tricks.com/how-to-do-knockout-text/ */
    /* if we can clip, do it */
    /* -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text; */

    /* what will show through the text
        ~ or ~
        what will be the background of that element */
    /* background: whatever; */

    /* fallback text color
        ~ or ~
        the actual color of text, so it better work on the background */
    color: white;
    border-left: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;
}

.pill-right-text {
    border-radius: 0 4px 4px 0;
    border-right: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;
}
