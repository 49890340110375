#manage-admins-page {
    color: #444;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 100px;
}

#manage-admins-content {
    max-width: 80vw;
}

#manage-admins-content > h1 {
    margin-bottom: 30px;
}

p#manage-admins-explanation {
    margin-bottom: 20px;
    font-size: 14pt;
}

#manage-admins-table {
    margin-bottom: 20px;
}

#manage-admins-add-admin {
    display: flex;
    align-items: center;
    padding: .4em .8em;
    border-radius: 8px;
    background-color: #00aeff;
    width: 120px;
    cursor: pointer;
    color: white;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

#manage-admins-add-admin:active {
    box-shadow: none;
    transform: translate(0, 2px);
}