div#testplan-details-load-container {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

div#testplan-details-page {
    margin-top: -40px;
    font-family: Roboto, NotoSans;
    color: #484848;
}

div#testplan-details-page h1, div#testplan-details-page h2, div#testplan-details-page h3 { 
    letter-spacing: .04em;
}

div#testplan-detail-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 60px;
}

h1#testplan-name {
    font-family: Roboto;
    font-size: 32pt;
}

div#testplan-header-pills {
    display: flex;
    gap: 30px;
}

div.section-divider {
    width: 90vw;
    font-family: Roboto-Light;
    font-size: 16pt;
    border-bottom: 1px solid #E4E4E4;
    padding: 10px 0;
    margin-bottom: 35px;
}

/**** Data Collection Events ****/
div#data-collection-events {
    margin-bottom: 60px;
}

div.data-collection-event-url-button {
    display: inline-block;
    cursor: pointer;
    border-radius: 2px;
    padding: .5em 2em;
}
div.data-collection-event-url-button.test {
    color: #E546FF;
    border: 1px solid #E546FF;
}
div.data-collection-event-url-button.test:hover {
    background-color: #fdeeff;
}

div.data-collection-event-url-button.results {
    color: #FF8D3A;
    border: 1px solid #FF8D3A;
}
div.data-collection-event-url-button.results:hover {
    background-color: #ffeee2;
}



div.new-data-collection-event-button {
    position: relative;
    left: 16px;
    display: flex;
    align-items: center;
    width: 100px;
    line-height: 100%;
    color: #75D42A;
    border: 1px solid #75D42A;
    cursor: pointer;
    border-radius: 2px;
    padding: .5em 2em;
}

div.new-data-collection-event-button:hover {
    background-color: #e9ffd9;
}