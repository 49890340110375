#login-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#small-screen-message {
    font-family: flexo-light;
    max-width: 80vw;
}

@media (min-width: 1001px) {
    #small-screen-message {
        display: none;
    }
}
@media (max-width: 1000px) {
    #login-tile-container {
        display: none;
    }
}


img#hc-logo {
    position: absolute;
    top: 40px;
    left: 40px;
    height: 80px;
}

div#login-tile-container {
    width: 500px;
}

div#login-tile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

div#login-tile p {
    width: 350px;
    margin-bottom: 40px;
    color: #333;
    font-family: Flexo-Light;
    line-height: 1.8em;
}

div#login-tile input {
    width: 350px;
    max-height: 32px;
    box-sizing: border-box;
    margin-bottom: 15px;
    border: none;
    border-bottom: 1px solid #222;
    border-top: 1px solid #fff;
    padding: .2em .1em;
    font-size: 14pt;
    font-family: Flexo;
    color: #222;
}
div#login-tile input:focus {
    border: none;
    outline: none;
    border-bottom: 2px solid #333;
    border-top: 2px solid #fff;
}

img#hcai-icon {
    display: block;
    margin-top: 40px;
    margin-bottom: 60px;
    height: 200px;
    width: 200px;
}

#login-button {
    width: 350px;
    box-sizing: border-box;
    padding: 1.1em;
    text-align: center;
    background-color: #4e4e4e;
    border-radius: 5px;
    color: white;
    font-size: 16pt;
    user-select: none;
}

#login-button.active {
    background-color: #00a859;
    cursor: pointer;
}

#login-button.active:hover {
    background-color: #039651;
}

/* iPad formatting */
@media (max-width: 1050px){
    img#hc-logo {
        position: relative;
        top: 0;
        left: 0;
    }
}


/* Phone formatting */
@media (max-width: 500px){
    img#hc-logo {
        height: 60px;
    }

    div#login-tile-container {
        width: 100%;
    }
    div#login-tile-container div.tile {
        box-shadow: none;
    }
}