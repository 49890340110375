/* Demo Result  */
div#demo-result-view-container {
    margin-left: 6%;
    margin-top: 6vh;
}
div#demo-result-view-container *{
    font-weight: normal;
}

h1#result-title {
    font-family: Flexo-Thin;
    font-size: 40pt;
    margin-bottom: 24px;
    margin-right: 100px;
}

div#event-name-and-metrics-container {
    margin-bottom: 6vh;
}

div.event-metrics-section {
    margin-bottom: 25px;
}

h3.event-metrics-header {
    font-family: Flexo-Demi;
    font-size: 16pt;
    color: #A5A5A5;
    margin-bottom: 6px;
}

h3.event-metrics-value {
    font-family: Flexo-Thin;
    font-size: 24pt;
    color: #333;
}

div#all-correct-container {
    display: flex;
    column-gap: 100px;;
}

div.all-correct-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2.all-correct-value {
    font-family: Flexo-Medium;
    font-size: 24pt;
    color: #333;
}

h2#healthcareai-all-correct-proportion.active {
    color: #00AEFF;
}

h2#pvalue-all-correct {
    font-family: Flexo-Thin;
}

h2#pvalue-all-correct.significant {
    font-family: Flexo-Medium;
}


/* Question Result */
div.question-result-container {
    position: relative;
    padding: 0 5px;
    margin-bottom: 8vh;
}

h2.question-result-title {
    font-family: Flexo;
    font-size: 20pt;
    margin-bottom: 25px;
    white-space: nowrap;
}

div.question-result-table-container {
    margin-left: 42px;
    white-space: nowrap;
}

div.question-result-table-header-container {
    margin-bottom: 55px;
}

span.question-result-header {
    display: inline-block;
    font-family: Flexo-Thin;
    font-size: 16pt;
    box-sizing: border-box;
}
span.question-result-header.left {
    text-align: left;
}
span.question-result-header.center {
    text-align: center;
}
span.question-result-header.plain {
    padding-left: 15px;
}

span.question-result-header.enhanced {
    margin-left: 28px;
}


div.chart-target {
    position: absolute;
    top: 75px;
    pointer-events: none;
}
div.chart-target.plain {
    left: 283px;
}
div.chart-target.enhanced {
    left: 860px;
    /* left: 750px; */
}

svg text.ylabel {
    font-family: Flexo-Thin;
    font-size: 12pt;
}

/* Question Result Table Row */
div.question-result-table-row-container {
    margin-left: -5px;
}
div.question-result-table-row-container.dark-row {
    background-color: #efefef;
}
div.question-result-table-row-container:hover {
    background-color: #d0f4ff;
}

div.result-row-cell {
    display: inline-block;
    line-height: 50px;
    font-family: Flexo;
}

div.result-row-cell.row-option {
    /* To cover our -5px margin for the table row container */
    margin-left: 5px;
    text-align: center;
    /* font-size: 18pt; */
}

div.result-row-cell.row-correct {
    text-align: center;
    vertical-align: middle;
}
div.result-row-cell.row-correct img {
    vertical-align: middle;
    height: 30px;
    width: 30px;
}

div.result-row-cell.row-p-value {
    text-align: center;
    font-family: Flexo-Thin;
    font-size: 12pt;
    color: #666;
}

div.result-row-cell.row-p-value.significant {
    font-family: Flexo-Heavy;
    color: #333;
}
