div#navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 53px;
    background-color: #384655;
}

div#navbar-icon {
    height: 53px;
    width: 53px;
    background-color: #00aeff;
    display: inline-block;
    vertical-align: middle;
}

div#navbar-title {
    display: inline-block;
    vertical-align: middle;
    color: white;
    font-family: Flexo;
    font-size: 24pt;
    padding: 0 .7em;
}

div#name {
    display: inline-block;
    color: white;
    padding: 0 1em 0 1em;
    height: 100%;
    line-height: 53px;
    vertical-align: middle;
}

div#name:hover {
    background-color: #2e3946;
    cursor: pointer;
}

div#logout-dropdown {
    position: absolute;
    top: 53px;
    right: 0px;
    z-index: 1000;
    color: #384655;
    background-color: #f6f6f6;
    height: 45px;
    line-height: 45px;
    padding: 0 1em;
}

div#logout-dropdown:hover {
    background-color: #e6e6e6;
}