/* Carousel */
#carousel-container {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    width: 320px;
    margin-left: 50px;
    opacity: 1;
    transition: 'all 2s ease';
}
#carousel-container #carousel-progress {
    color: #999;
    margin-bottom: .7em;
    font-size: 10pt;
}

@media (max-width: 500px) {
    #carousel-container {
        position: relative;
        max-width: 90vw;
    }
}

/* Question */
div#carousel-question-container {
    margin-bottom: 50px;
}
#carousel-question-text {
    font-size: 14pt;
    margin-bottom: 10px;
}

#carousel-selection-instructions {
    margin-bottom: 25px;
    position: relative;
    left: 10px;
    color: #555;
}

/* Question Timer */
div#question-timer-container {
    width: 250px;
    margin-bottom: 20px;
}
#question-timer-info-text {
    font-size: 9pt;
}
#question-timer-seconds-text {
    font-size: 28pt;
}

@media (max-width: 500px) {
    div#question-timer-container {
        width: 70px;
        position: relative; 
        left: -10px;
    }
    #question-timer-seconds-text {
        font-size: 24pt;
    }
}


/* Option */
div.carousel-question-option {
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 11pt;
    padding: .4em 1em;
    margin-bottom: 7px;
    border: 3px solid #888;
    background-color: rgb(248, 248, 248);
    cursor: pointer;
    color: #222;
}
div.carousel-question-option.selected {
    border: 3px solid #00aeff;
    background-color: #ade5ff;
}

.button-submit {
    width: 200px;
    padding: .5em;
    text-align: center;
    background-color: #00a859;
    border-radius: 5px;
    color: white;
    font-size: 16pt;
    cursor: pointer;
}