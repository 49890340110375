#testplans-page {
    color: #444;
    padding: 60px;
}

#testplans-content h1 {
    margin-bottom: 30px;
}

#testplans-table {
    max-width: 90vw;
}

#testplans-page span.test-mode {
    border-radius: 4px;
    padding: .4em .6em;
    font-weight: bold;
}

#testplans-page span.test-mode.paired {
    background-color: #B2D4FD;
    color: #005DCB;
}

#testplans-page span.test-mode.independent {
    background-color: #FFEED8;
    color: #EE8B00;
}

.MuiTableRow-hover {
    cursor: pointer;
}