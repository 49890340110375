#admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 83px;
    background-color: white;
    border-bottom: 1px solid #E4E4E4;
}

#admin-header > div {
    display: flex;
    align-items: center;
}

#admin-header-title {
    margin-left: 40px;
    cursor: pointer;
}

#admin-header-title > span {
    font-size: 36pt;
}

#admin-header-title > span:nth-of-type(1) {
    font-family: Flexo-Thin;
}

#admin-header-title > span:nth-of-type(2) {
    font-family: Flexo-Medium;
    margin-left: 16px;
}

#admin-header-right-items {
    margin-right: 20px;
}

#admin-header-right-items * {
    cursor: pointer;
}

#admin-header-right-items span#admin-header-avatar-container {
    display: flex;
    align-items: center;
}

#admin-header-right-items span#admin-header-setting-icon {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

#admin-header-right-items span#admin-header-username {
    margin-right: 10px;
}
