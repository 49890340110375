div.stimulus-bundle-detail-single-bundle  {
    margin-bottom: 70px;
}

div.stimulus-bundle-detail-single-header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
}

div.stimulus-bundle-detail-single-header > h1 {
    font-family: Roboto-Light;
    font-size: 24pt;
}

div.stimulus-bundle-detail-image-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

div.stimulus-bundle-detail-image-container h3 {
    font-family: Roboto-Light;
    font-size: 16pt;
    margin-bottom: 15px;
}

div.sbd-tile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

div.tag-placeholder {
    height: 31px;
}
div.image-none {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 700px;
    height: 400px;
    background-color: #F3F3F3;
    color: white;
    font-family: Roboto;
    font-size: 72pt;
}

div.stimulus-bundle-detail-image-frame {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 700px;
    height: 400px;
    border: 20px solid #F3F3F3;
    box-sizing: border-box;
    background-color: #F3F3F3;
}

div.stimulus-bundle-detail-image-frame > * {
    max-height: 100%;
    max-width: 100%;
}