#split-mode-conclusion {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    height: 90vh;
}

#split-mode-conclusion h1 {
    color: #00aeff;
    font-family: NotoSans;
    font-size: 30pt;
}

#split-mode-conclusion h2 {
    color: #00aeff;
    font-family: Flexo;
    font-size: 22pt;
}

#stamp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 700px;
    height: 800px;
}

#stamp-image {
    max-height: 600px;
}