/* Stimulus Bundle */
div#stimulus-bundle-container {
    display: flex;
    justify-content: space-around;
    min-height: 90vh;
}

div#question-container {
    min-width: 1000px;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding-top: 20px;
    height: 90vh;
    margin: 0 20px 0 20px;
}

div#stimulus-container {
    margin: -20px -25px;
}

div#question-container img {
    max-width: 100%;
}

@media (max-width: 500px) {
    div#question-container {
        width: 100%;
    }
    div#question-container div.tile {
        box-shadow: none;
        padding: 0 0;
    }
    div#stimulus-container {
        width: 95vw;
        margin-left: 0;
        margin-right: 0;
    }
}


/* Static Page */
div#static-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

div#static-page-container img {
    max-width: 90vw;
    max-height: 90vh;
    margin-bottom: 15px;
}

.inactive-button {
    background-color: darkslategrey;
    cursor: default;
}

.button-next {
    min-width: 200px;
    padding: .5em;
    text-align: center;
    background-color: #00a859;
    border-radius: 5px;
    color: white;
    font-size: 16pt;
    cursor: pointer;
}

div#button-static-continue {
    background-color: #00aeff;
    color: white;
}

div#button-static-continue:hover {
    background-color: #3dbefa;
}

div#button-static-continue.inactive-button {
    background-color: darkslategrey;
    cursor: default;
}

div#button-static-continue.inactive-button:hover {
    background-color: darkslategrey;
}

@media (max-width: 500px) {
    div#static-page-container img {
        max-width: 100vw;
    }
}